import { Field, Form, Formik } from "formik";
import React, { FC, useState } from "react";
import { Icon } from "react-icons-kit";
import { check } from "react-icons-kit/metrize/check";
import { Flex, Text } from "rebass";
import Input from "../components/Input";
import Textarea from "../components/Textarea";
import { theme } from "../lib/theme";
import Button from "./Button";

const fields = {
  name: "姓名",
  email: "電郵",
  phone: "電話",
  message: "訊息"
};

export const getFieldName = (key: string) => fields[key];

type Props = {
  initialValues: { [key: string]: string | number };
  validationSchema: {};
  successText?: string;
  fetchLink: string;
  submitText?: string;
  disabled?: boolean;
};

const ActionForm: FC<Props> = ({
  initialValues,
  validationSchema,
  successText = "",
  fetchLink,
  submitText = "送出",
  disabled,
  children
}) => {
  const [success, setSuccess] = useState(false);
  const [errorText, setErrorText] = useState("");

  return success ? (
    <Flex
      my={2}
      py={4}
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
    >
      <Icon
        icon={check}
        css={` color ${props => props.theme.colors.success}`}
        size={184}
      />

      <Text
        textAlign="center"
        width={1}
        fontWeight="bold"
        fontSize={[5]}
        color={theme.colors.success}
        mt={6}
      >
        {successText}
      </Text>
    </Flex>
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        typeof window !== "undefined" &&
          // @ts-ignore
          window.gtag("event", "conversion", {
            send_to: "AW-734443043/bcX9CMmRqqUBEKPsmt4C"
          });
        try {
          const res = await fetch(fetchLink, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(values)
          });

          if (res.status === 200) {
            actions.resetForm();
            setSuccess(true);
          } else if (res.status === 500) {
            setErrorText("傳送失敗，請稍後再試");
          }

          actions.setSubmitting(false);
        } catch (err) {
          setErrorText("傳送失敗，請稍後再試");
          actions.setSubmitting(false);
        }
      }}
      render={formikBag => (
        <Form>
          {children}
          {Object.keys(initialValues).map((key, i) => {
            if (key === "message") {
              return (
                <Field
                  key={`form-field-${i}`}
                  name={key}
                  render={({ field, form }) => (
                    <Textarea
                      {...field}
                      disabled={formikBag.isSubmitting}
                      error={formikBag.errors[key]}
                      touched={formikBag.touched[key]}
                      label={getFieldName(key) || key}
                    />
                  )}
                />
              );
            }
            return (
              key !== "datetime" && (
                <Field
                  key={`form-field-${i}`}
                  name={key}
                  render={({ field, form }) => (
                    <Input
                      {...field}
                      disabled={formikBag.isSubmitting}
                      error={formikBag.errors[key]}
                      touched={formikBag.touched[key]}
                      label={getFieldName(key) || key}
                    />
                  )}
                />
              )
            );
          })}

          <Flex
            width={1}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              type="submit"
              width={[1 / 2, 1 / 3]}
              mx="auto"
              loading={formikBag.isSubmitting}
              disabled={disabled}
              mt={2}
            >
              {submitText}
            </Button>
            {errorText && (
              <Text
                width={1 / 2}
                fontSize={[1, 2]}
                fontWeight="bold"
                textAlign={["center"]}
                color="danger"
                p={2}
              >
                {errorText}
              </Text>
            )}
          </Flex>
        </Form>
      )}
    />
  );
};

export default ActionForm;
