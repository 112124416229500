import styled from "styled-components";

const SectionWrapper = styled.section`
  @keyframes ScaleInUp {
    from {
      opacity: 0;
      transform: translateY(30px) scale(0.97);
    }
    to {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  .update-screen-tab {
    border: 0;
    overflow: initial;
    .rc-tabs-ink-bar {
      display: none !important;
    }
    .rc-tabs-bar {
      border: 0;
      margin-bottom: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .rc-tabs-nav-container {
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background: #e7e8eb;
        display: block;
        left: 0;
        bottom: 0;
        z-index: -1;
      }
      &:not(.rc-tabs-nav-container-scrolling) {
        .rc-tabs-nav-scroll {
          width: 100%;
          text-align: center;
          .rc-tabs-nav {
            float: none;
            display: block;
            .rc-tabs-tab {
              display: inline-block;
              float: none;
            }
          }
        }
      }
    }
    .rc-tabs-content {
      .rc-tabs-tabpane {
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 5px 60px 0px rgba(27, 67, 111, 0.15);
        &.rc-tabs-tabpane-active {
          animation: 0.7s ScaleInUp;
        }
        > img {
          max-width: 100%;
          height: auto;
          display: block;
        }
      }
    }
  }

  .rc-tabs-tab-prev-icon,
  .rc-tabs-tab-next-icon {
    font-size: 20px;
    color: #ff4362;
    line-height: 1;
    display: block;
  }

  .rc-tabs-tab {
    font-size: 18px;
    color: #6f7a87;
    font-weight: 400;
    min-width: 230px;
    padding: 0 0 25px 0;
    padding-bottom: 1rem;
    text-align: center;
    margin: 0 auto;
    transition: 0.25s ease-in-out;
    &:hover {
      color: ${props => props.theme.colors.info};
    }
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      display: block;
    }
    &:after {
      background: ${props => props.theme.colors.primary};
      transform: scaleX(0);
      transform-origin: right center 0;
      transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    }
    &.rc-tabs-tab-active {
      color: ${props => props.theme.colors.primary};
      &:after {
        transform: scaleX(1);
        transform-origin: left center 0;
        transition: transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68);
      }
    }
    > div {
      // margin-right: 8px;
    }
    @media (max-width: 1199px) {
      font-size: 16px;
      // padding: 0 0 20px 0;
      padding: 0;
      // min-width: 170px;
    }
    @media (max-width: 990px) {
      min-width: auto;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      svg {
        width: 20px;
      }
    }
  }
`;

export default SectionWrapper;
