import React from "react";
import TextareaWrapper, { ErrorText, InputLabel } from "./textarea.style";

const Input = ({ label, error = false, touched = false, ...rest }) => {
  return (
    <TextareaWrapper>
      <InputLabel className="label">
        {label} {touched && error && <ErrorText>{error}</ErrorText>}
      </InputLabel>
      <textarea rows={3} {...rest} />
    </TextareaWrapper>
  );
};

export default Input;
