import { graphql } from "gatsby";
import React from "react";
import Fade from "react-reveal/Fade";
import { Heading } from "rebass";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import SEO from "../components/Seo";
import SeminarSection from "../container/SeminarSection";

const SeminarPage = ({ data }) => {
  return (
    <Layout transparentHeader>
      <SEO title="移民講座" />

      <PageHeader backgroundImage={data.bg.childImageSharp.fluid}>
        <Fade delay={100}>
          <Heading color="white" fontSize={[5, 7]}>
            移民講座
          </Heading>
          <Heading
            mt={2}
            textAlign="center"
            fontSize={[2, 4]}
            color="rgba(255,255,255,0.8)"
          >
            把握人生機遇，彰顯精彩人生
          </Heading>
        </Fade>
      </PageHeader>

      <SeminarSection />
    </Layout>
  );
};

export default SeminarPage;

export const query = graphql`
  {
    bg: file(relativePath: { eq: "bg-8.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
