import format from "date-fns/format";
import zh_cn from "date-fns/locale/zh_cn";
import { graphql, useStaticQuery } from "gatsby";
import Tabs, { TabPane } from "rc-tabs";
import "rc-tabs/assets/index.css";
import ScrollableInkTabBar from "rc-tabs/lib/InkTabBar";
import TabContent from "rc-tabs/lib/TabContent";
import React, { useState } from "react";
import { Icon } from "react-icons-kit";
import { ic_dvr } from "react-icons-kit/md/ic_dvr";
import { ic_person } from "react-icons-kit/md/ic_person";
import { Box, Heading } from "rebass";
import * as Yup from "yup";
import ActionForm from "../../components/ActionForm";
import Text from "../../components/elements/Text";
import Section from "../../components/Section";
import Select from "../../components/Select";
import { phoneRegex } from "../../lib/phoneRegex";
import SectionWrapper from "./seminar.style";

const SeminarSection = ({ defaultTab = "seminar" }) => {
  const { seminarsMarkdown } = useStaticQuery(graphql`
    {
      seminarsMarkdown: markdownRemark(
        fileAbsolutePath: { regex: "/seminar/" }
      ) {
        frontmatter {
          seminars {
            id
            start
            end
            location
            isDisabled
          }
        }
      }
    }
  `);
  const [selected, setSelected] = useState(
    seminarsMarkdown.frontmatter.seminars.find(s => {
      if (s.isDisabled) return;
      return s;
    })
  );

  return (
    <SectionWrapper>
      <Section py={[2, 3]} px={1} maxWidth="1024px">
        <Tabs
          className="update-screen-tab"
          defaultActiveKey={defaultTab}
          renderTabBar={() => <ScrollableInkTabBar />}
          renderTabContent={() => <TabContent animated={false} />}
        >
          <TabPane
            tab={
              <>
                <Icon
                  icon={ic_dvr}
                  size={38}
                  css={`
                    margin-right: 0.5rem;
                  `}
                />
                投資移民講座
              </>
            }
            key="seminar"
          >
            <Box width={1} py={[2, 2, 4]} mb={[2, 2, 6]}>
              <Heading color="success" mt={3} mb={6} px={3} textAlign="center">
                免費講座，讓你了解更多
              </Heading>

              <Box width={[1, 2 / 3, 2 / 3]} mx="auto">
                <ActionForm
                  successText="我們已為你安排"
                  submitText="報名"
                  fetchLink={`/.netlify/functions/seminar`}
                  disabled={!selected}
                  initialValues={{
                    name: "",
                    phone: "",
                    email: "",
                    datetime: selected
                      ? `${format(selected.start, "DD MMM, YYYY")} - ${format(
                          selected.start,
                          "HH:mm A"
                        )} ~ ${format(selected.end, "HH:mm A")}`
                      : null
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required("請輸入姓名"),
                    phone: Yup.string()
                      .matches(phoneRegex, "電話號碼不正確")
                      .required("請輸入聯絡電話"),
                    email: Yup.string()
                      .email("電郵格式不正確")
                      .required("請輸入電郵")
                  })}
                >
                  <Text
                    mb={4}
                    px={3}
                    fontSize={[3]}
                    fontWeight="bold"
                    textAlign="center"
                    content="地點: 香港中環干諾道中 30-32 號莊士大廈11樓"
                  ></Text>

                  <Select
                    mb={2}
                    width={"90%"}
                    mx="auto"
                    selectProps={{
                      placeholder: "時間",
                      defaultValue: selected,
                      isSearchable: false,
                      formatOptionLabel: o =>
                        `${format(o.start, "M月D號")} ~ ${format(
                          o.start,
                          "Ah點",
                          { locale: zh_cn }
                        )} 至 ${format(o.end, "Ah點", { locale: zh_cn })}`,
                      getOptionValue: o => o.id,
                      onChange: (selected, { action }) => {
                        setSelected(selected);
                      },
                      options: seminarsMarkdown.frontmatter.seminars
                    }}
                  ></Select>
                </ActionForm>
              </Box>
            </Box>
          </TabPane>
          <TabPane
            tab={
              <>
                <Icon
                  icon={ic_person}
                  size={38}
                  css={`
                    margin-right: 0.5rem;
                  `}
                />
                一對一諮詢
              </>
            }
            key="advisory"
          >
            <Box width={1} py={[2, 2, 4]} mb={[2, 2, 6]}>
              <Heading color="success" mt={3} px={3} mb={6} textAlign="center">
                我們的移民顧問，為你解答任何疑問
              </Heading>

              <Box width={[1, 2 / 3, 2 / 3]} mx="auto">
                <ActionForm
                  successText="我們已為你安排"
                  submitText="預約"
                  fetchLink={`/.netlify/functions/advisory`}
                  initialValues={{
                    name: "",
                    phone: "",
                    email: "",
                    message: ""
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required("請輸入姓名"),
                    phone: Yup.string()
                      .matches(phoneRegex, "電話號碼不正確")
                      .required("請輸入聯絡電話"),
                    email: Yup.string()
                      .email("電郵格式不正確")
                      .required("請輸入電郵")
                  })}
                />
              </Box>
            </Box>
          </TabPane>
        </Tabs>
      </Section>
    </SectionWrapper>
  );
};

export default SeminarSection;
