import styled from "styled-components";

const TextareaWrapper = styled.div`
  * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }

  width: 90%;
  margin: 0 auto 1rem auto;
  padding: 0.3rem 0;
  position: relative;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 12px;
  background: white;

  input,
  textarea {
    outline: none;
    border: none;
  }

  textarea {
    padding: 0.4rem 0.8rem;
    display: block;
    width: 100%;
    background: transparent;
    font-size: 18px;
    color: ${props => props.theme.colors.default};
    line-height: 1.1;
  }
`;

export const InputLabel = styled.label`
  font-size: 1rem;
  color: #666666;
  padding: 0 0.8rem;
`;

export const ErrorText = styled.span`
  color: ${props => props.theme.colors.danger};
  margin: 0 1rem;
  float: right;
`;

export default TextareaWrapper;
