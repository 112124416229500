import React from "react"
import InputWrapper, { InputLabel, ErrorText } from "./input.style"

const Input = ({ label, error = false, touched = false, ...rest }) => {
  return (
    <InputWrapper>
      <InputLabel className="label">
        {label} {touched && error && <ErrorText>{error}</ErrorText>}
      </InputLabel>
      <input {...rest} />
    </InputWrapper>
  )
}

export default Input
